.react-datepicker
  ul
    padding: 0
.react-datepicker-wrapper
  input:focus
    box-shadow: none
  button.react-datepicker__close-icon
    position: absolute
    right: 5px
.form-group.date
  .input-group
    flex: 0 0 75%
    cursor: pointer
.react-datepicker-popper
  z-index: 999

.default-form .preparation
  .input-group
    padding-left: 0px

